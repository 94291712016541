import * as React from "react";
import "./switch.css";
import { useEffect, useState } from "react";
import { tokenDecode } from "../../utils/helper";
import axios from "axios";
import styled from "styled-components";
import { config } from "../../utils/config";
import Circularprogress from "../../components/Generics/Circularprogress";
import Applyfilter from "../../components/Applyfilter/Applyfilter";
import ReactPaginate from 'react-paginate';
import ThemeMaker from "../../components/thememaker";
const { apiEndpoint } = config
const ImageContainer = styled.div
  `
  max-height: 64px;
  width: 25%;
  
  img{
    width: 100%!important;
    height: 68px;
  object-fit: contain;
}
  `

const Mastercatalog = () => {
  const decoded = tokenDecode();
  let current_sellerid = decoded.data.user.seller_id;
  // console.log("current Seller id:-----", current_sellerid)
  const decode = tokenDecode();
  const { data } = decode;
  const { permission: { product }, } = decoded?.data;
  const [productlist, setproductlist] = useState([]);
  const [noproducts, setNoproducts] = useState(false);
  const [productisLoading, setproductisLoading] = useState(false);
  const [searchValue, setsearchValue] = useState({
    name: "",
    searchFilter: ""
  })


  useEffect(() => {
    getproductlist();
  }, []);

  useEffect(() => {
    // When productlist changes, update itemCheckboxes to reflect the current state
    const checkboxes = {};
    productlist.forEach((item) => {
      checkboxes[item.id] = item.in_store;
    });
    setItemCheckboxes(checkboxes);
  }, [productlist]);

  async function getproductlist() {
    let parentId = decoded?.data?.user?.parent_id;
    let SellerId = decoded?.data?.user?.seller_id;
    let payload;
    if (parentId > 1) {
      payload = { seller_id: parentId }

    } else {
      payload = { seller_id: SellerId }

    }
    setproductisLoading(true)
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/showallproductmasters`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,
        },
        data: payload,
      });
      // console.log("data of master product list-->", data)
      if (data) {
        setNoproducts(false)
        setproductlist(data.data);
        setproductisLoading(false)

      }
    } catch (error) { }
  }

  function searchHandler(e) {
    setsearchValue({ ...searchValue, [e.target.name]: e.target.value });
    if (e.target.value == "") {
      getproductlist();
    }
  }
  function selectHandler(e) {
    setsearchValue({ ...searchValue, searchFilter: e.target.value });
  }

  async function searchResult(e) {
    // console.log("searchValue", e.key);
    if (e.key == "Enter") {
      try {
        const { data } = await axios({
          url: `${apiEndpoint}/searchproduct`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,
          },
          data: {
            type: searchValue.searchFilter,
            name: searchValue.name,
          },
        });
        // console.log("data of /=master product list-->", data)
        if (data.status == 200) {
          setproductlist(data?.data);
          if (data?.data?.length < 1) {
            setNoproducts(true)
          }
        } else {
          setproductlist([]);
        }
      } catch (error) { }
    }
  }

  // function handleSort(e) {
  //   if (e.target.value == 0) {
  //     const sortedLowToHigh = [...productlist].sort((a, b) => {
  //       return parseInt(a.mrp) - parseInt(b.mrp);
  //     });
  //     setproductlist(sortedLowToHigh);
  //   } else if (e.target.value == 1) {
  //     const sortedHightoLow = [...productlist].sort((a, b) => {
  //       return parseInt(b.mrp) - parseInt(a.mrp);
  //     });
  //     setproductlist(sortedHightoLow);
  //   } else {
  //     getproductlist();
  //   }
  // }
  function handleSort(e) {
    try {
      const sortOrder = e.target.value;
      if (!productlist || !Array.isArray(productlist)) {
        console.error("Product list is not available or not an array.");
        return;
      }
      if (sortOrder === "") {
        getproductlist();
        return;
      }
      const sortedList = [...productlist].sort((a, b) => {
        const priceA = Number(a.mrp) || 0;
        const priceB = Number(b.mrp) || 0;
        return sortOrder === "0" ? priceA - priceB : priceB - priceA;
      });
      setproductlist(sortedList);
    } catch (error) {
      console.error("Error in handleSort:", error);
    }
  }
  

  //---------------------------------------------- code  for pagination------------------------------------------------
  const [itemCheckboxes, setItemCheckboxes] = useState({});
  useEffect(() => {
    // When productlist changes, update itemCheckboxes to reflect the current state
    const checkboxes = {};
    productlist.forEach((item) => {
      checkboxes[item.id] = item.in_store;
    });
    setItemCheckboxes(checkboxes);
  }, [productlist]);

  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + 10;
  const currentItems = productlist.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(productlist.length / 10);
  const handlePageClick = (event) => {
    window.scrollTo(0, 0)
    const newOffset = (event.selected * 10) % productlist.length;
    setItemOffset(newOffset);
  };
  //---------------------------------------------- code for pagination------------------------------------------------


  function updateProductList(productList) {
    setproductlist(productList)
  }

  // console.log("roductList------->", productlist)

  return (
    <>
      <ThemeMaker>
        <div className="row w-100 m-0 mt-2">
          {/* <div className="col-md-12 ">
            <div className="card shadow mb-4 mt-1">
              <div className="card-header py-3">
                <div className="row d-flex justify-content-between">
                  <h6 className="m-0 font-weight-bold text-primary mb-2">Master Catalog</h6>
                </div>
                <div className="row mx-auto">
                  <div className="col-12">
                    <div className="d-flex searchDiv">
                      <select
                        name="searchFilter"
                        id=""
                        className="m-0"
                        onChange={selectHandler}
                        value={searchValue.searchFilter}
                      >
                        <option value="">Search By</option>
                        <option value="Product">Product</option>
                        <option value="Brand">Brand</option>
                        <option value="Category">Category</option>
                      </select>

                      <input
                        type="search"
                        name="name"
                        placeholder="Product, Category or Brand"
                        className="w-100 mr-2 rounded-pill m-0"
                        value={searchValue.name}
                        onChange={searchHandler}
                        onKeyPress={searchResult}
                        style={{ fontSize: "14px" }}
                      />
                    </div>
                  </div>
                  <div className="col-12 d-flex btn-toolbar p-0 d-flex align-items-center" role="toolbar" style={{ gap: "10px" }}>
                    <Applyfilter updateProductList={updateProductList} getproductlist={getproductlist} url="filtermastercatalog"></Applyfilter>
                    <div className="btn-group sortWidth" role="group" style={{ width: "10%" }}>
                      <select type="button" className="btn btn-secondary w-100  "
                        style={{ fontSize: "14px", margin: "5px 0px" }}
                        onChange={handleSort}

                      >
                        <option value="">Sort</option>
                        <option value="0">Price High to Low</option>
                        <option value="1">Price Low to High</option>
                      </select>
                    </div>
                  </div>
                  {productisLoading ? <Circularprogress /> : <>{!noproducts ? <> {
                    currentItems?.map((item, index) => {

                      let images = item?.image.split(",");
                      let image = images[0]

                      return <div className="d-flex w-100 align-items-center mb-2  py-2" style={{ gap: "3px", position: "relative", background: "white" }} key={item.id}>

                        <ImageContainer>
                          <img src={image} className="w-100" alt="" />
                        </ImageContainer>
                        <div className="prod_desc">
                          {item.name} <br />
                          Category : {item.category_name}
                          <br />
                          MRP : Rs{item.mrp} <br />

                        </div>


                        {product.update ? <div className="position-absolute" style={{ top: "3px", right: "5px" }}>
                          <label className="switch">
                            <input type="checkbox" checked={itemCheckboxes[item.id]} onChange={async (e) => {
                              const itemId = item.id;
                              const isChecked = !itemCheckboxes[item.id];
                              setItemCheckboxes({ ...itemCheckboxes, [itemId]: isChecked });
                              const { data } = await axios({
                                url: `${apiEndpoint}/addmastercatalogproduct`,
                                method: "POST",
                                headers: {
                                  Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,
                                },
                                data: { id: itemId, isCheked: isChecked },
                              });


                            }} />
                            <span className="slider round"></span>
                          </label>
                        </div> : null}
                      </div>
                    })
                  } </> : <p className="w-100" style={{ fontSize: "16px", color: "black", fontWeight: "600", textAlign: "center" }}>No such products</p>}</>}

                  <div className='pagination_main'>
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel=">"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={1}
                      marginPagesDisplayed={1}
                      pageCount={pageCount}
                      previousLabel="<"
                      renderOnZeroPageCount={null}
                      activeLinkClassName="current_active"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="container-fluid">
            <div className="card shadow-sm mb-4 mt-3">
              <div className="card-header py-3 d-flex justify-content-between align-items-center">
                <h6 className="m-0 font-weight-bold text-primary">Master Catalog</h6>
              </div>
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-md-4 d-flex align-items-center">
                    <Applyfilter updateProductList={updateProductList} getproductlist={getproductlist} url="filtermastercatalog" />
                    <select className="form-control" onChange={handleSort}>
                      <option value="">Sort</option>
                      <option value="1">Price High to Low</option>
                      <option value="0">Price Low to High</option>
                    </select>
                  </div>
                  <div className="col-md-8">
                    <div className="input-group">
                      <select className="form-control bg-gradient-light" onChange={selectHandler} value={searchValue.searchFilter}>
                        <option value="">Search By</option>
                        <option value="Product">Product</option>
                        <option value="Brand">Brand</option>
                        <option value="Category">Category</option>
                      </select>
                      <input
                        type="search"
                        className="form-control w-50"
                        placeholder="Product, Category, or Brand"
                        value={searchValue.name}
                        onChange={searchHandler}
                        onKeyPress={searchResult}
                      />
                    </div>
                  </div>
                </div>
                {/* Product List */}
                {productisLoading ? (
                  <div className="text-center">
                    <Circularprogress />
                  </div>
                ) : (
                  <>
                    {!noproducts ? (
                      currentItems?.map((item) => {
                        let image = item?.image.split(",")[0];
                        return (
                          <div className="row align-items-center py-2 border-bottom" key={item.id}>
                            <div className="col-md-2">
                              <img src={image} className="img-fluid rounded" alt="" />
                            </div>
                            <div className="col-md-7">
                              <h6 className="mb-1">{item.name}</h6>
                              <p className="mb-1 text-muted">Category: {item.category_name}</p>
                              <p className="mb-0 font-weight-bold">MRP: Rs {item.mrp}</p>
                            </div>
                            {product.update && (
                              <div className="col-md-3 text-right">
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    checked={itemCheckboxes[item.id]}
                                    onChange={async (e) => {
                                      const itemId = item.id;
                                      const isChecked = !itemCheckboxes[item.id];
                                      setItemCheckboxes({ ...itemCheckboxes, [itemId]: isChecked });

                                      await axios.post(`${apiEndpoint}/addmastercatalogproduct`,
                                        { id: itemId, isChecked },
                                        { headers: { Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}` } });
                                    }}
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </div>
                            )}
                          </div>
                        );
                      })
                    ) : (
                      <p className="text-center font-weight-bold text-dark">No such products</p>
                    )}
                  </>
                )}

                {/* Pagination */}
                <div className='pagination_main'>
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel=">"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={1}
                      marginPagesDisplayed={1}
                      pageCount={pageCount}
                      previousLabel="<"
                      renderOnZeroPageCount={null}
                      activeLinkClassName="current_active"
                    />
                  </div>
              </div>
            </div>
          </div>

        </div>
      </ThemeMaker>
    </>
  );
};

export default Mastercatalog;
