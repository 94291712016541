import { Link } from "react-router-dom";
import { tokenDecode } from "../../../utils/helper";
import { useState } from "react";
// import logoimg from "../../../../src/icons/logoimg.png";
import logoimg from "../../../../src/icons/xpressbazarlogo.jpg"
import styles from "./Side_bar.module.css";
import { useEffect } from "react";
import { config } from "../../../utils/config";
import axios from "axios";
import jwt_decode from "jwt-decode";
import dashboardicon from "../../../icons/dashboardicon.png";
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import PaidIcon from '@mui/icons-material/Paid';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import ListAltIcon from '@mui/icons-material/ListAlt';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import GroupIcon from '@mui/icons-material/Group';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import Navbar from "../navbar";
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import { useHistory } from "react-router-dom";
import { BarChart } from '@mui/x-charts/BarChart';
import MovingIcon from '@mui/icons-material/Moving';
const { apiEndpoint } = config;
const Sidebar = () => {
  const [arrows, setArrows] = useState({});
  const decoded = tokenDecode();
  const history = useHistory()
  const { data } = decoded;
  const { permission: { category, order, product, role, seller, tax, user, location, product_master_catalog, igm, transaction }, } = data;
  // console.log("permissions------->", data.permission)
  if (decoded) {
    // if (isAdmin === "true") {
    //     return children;
    // }
  } else {
    console.log("no");
  }

  const [numberOfNAP, setnumberOfNAP] = useState([]);

  const [sidebarShow, setsidebarShow] = useState(checkstatus());

  function checkstatus() {
    const token = sessionStorage.getItem("us_st_d");
    const tokenDecode = jwt_decode(token);
    // console.log("tokendecode from siebar---->", tokenDecode)

    return tokenDecode.data.user.approve_status;
  }
  // console.log("seller approve status from sidebar---->", sidebarShow)
  useEffect(() => {
    async function getNAP() {
      try {
        const { data } = await axios({
          url: `${apiEndpoint}/listNonApproveProductSeller`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,
          },
          data: {
            seller_id: decoded?.data?.user?.seller_id,
          },
        });

        if (data.status === 200) {
          setnumberOfNAP(data.data);
          // console.log(data)
        }
      } catch (error) { }
    }
    getNAP();

    function handleResize() {
      const location = window.location.href.split("/")
      // console.log(location[location.length - 1])
      if (window.innerWidth > 766) {
        if (location[location.length - 1] == "sidebar") {
          history.push("/dashboard")
        }
      }
    }

    window.addEventListener('resize', handleResize);

  }, []);


  const seriesA = {
    data: [2, 3, 1],
    // label: 'Series A',
  };
  const seriesB = {
    data: [3, 1, 4],
    // label: 'Series B',
  };
  const seriesC = {
    data: [3, 2, 4],
    // label: 'Series C',
  };


  // console.log(numberOfNAP)

  // console.log("form sidebar>>>>", decoded.data.user.seller_id)
  let current_sellerid = decoded.data.user.seller_id;

  const handleClick = (id) => {
    setArrows((prevArrows) => {
      const newArrows = Object.fromEntries(
        Object.entries(prevArrows).map(([key, value]) => [key, false])
      );
      newArrows[id] = !prevArrows[id];
      return newArrows;
    });
  };

  return (
    <>
      <div className={styles.sidebar_navbar}>
        <Navbar></Navbar>
      </div>
      {sidebarShow == 1 ? (
        <ul
          className={`navbar-nav sidebar-dark accordion ${styles.side_bar}`}
          id="accordionSidebar"
        >
          <Link to="/dashboard">
            <a
              href="replace"
              className="sidebar-brand d-flex align-items-center justify-content-center"
            >
              <div className="sidebar-brand-icon rotate-n-15"></div>
              <div className="sidebar-brand-text ">
                {" "}
                <img
                  src={logoimg}
                  style={{ height: "85px", padding: "10px" }}
                  alt="logo here"
                  className={styles.sideBarLogo}
                />
              </div>
            </a>
          </Link>
          <hr className={`sidebar-divider mt-0 ${styles.sideBarLogo}`} />

          <li className={`nav-item active mb-2`}>
            <Link to="/dashboard">
              <a
                href="replace"
                className={`nav-link d-flex align-items-center justify-content-center ${styles.sidebarHeading}`}
                style={{ gap: "15px" }}
              >
                {/* <i className="fas fa-users-cog" ></i> */}
                {/* <img src={dashboardicon} alt="" /> */}
                <DashboardCustomizeIcon className={styles.icon_color}></DashboardCustomizeIcon>
                <span>Dashboard</span>
              </a>
            </Link>
          </li>




          {product?.read ? <>
            {/* <hr className="sidebar-divider mt-0" /> */}
            <div className="sidebar-heading">Management</div>
            {/* MasterCatalog  field:- */}
            {(category?.write || category?.read) ? (
              <>
                <li className="nav-item">
                  <Link to="#">
                    <a
                      className="nav-link collapsed d-flex justify-content-between myaddedclass"
                      href="replace"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="true"
                      aria-controls="collapseTwo"
                      id="products"
                      onClick={() => handleClick("products")}
                    >
                      <div className={styles.listItems}>
                        {/* <i className="fas fa-fw fa-cog"></i> */}
                        <PendingActionsIcon className={styles.icon_color}></PendingActionsIcon>
                        {current_sellerid == 1 ? (
                          <span>Category, Brand & Products </span>
                        ) : null}
                      </div>
                      <i
                        className={
                          arrows["products"]
                            ? `fas fa-angle-down`
                            : `fas fa-angle-right`
                        }
                      ></i>
                    </a>
                  </Link>
                  <div
                    id="collapseTwo"
                    className="collapse"
                    aria-labelledby="headingTwo"
                    data-parent="#accordionSidebar"
                  >
                    <div
                      className={`bg-white collapse-inner rounded ${styles.tabsInnerDiv}`}
                    >
                      {category?.write ? (
                        <Link to="/newcategory">
                          <a
                            href="replace"
                            className={`collapse-item  ${styles.collapseitemText}`}
                          >
                            Category
                          </a>
                        </Link>
                      ) : null}

                      {category?.write && (
                        <Link to="/brand">
                          <a
                            href="replace"
                            className={`collapse-item  ${styles.collapseitemText}`}
                          >
                            Brand
                          </a>
                        </Link>
                      )}

                      {(category?.write || category?.read) && (
                        <Link to="/producttype">
                          <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}                        >
                            Product Type
                          </a>
                        </Link>
                      )}

                      {(current_sellerid < 2 && current_sellerid !== 0) && (
                        <Link to="/productlist">
                          <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}   >
                            Products
                          </a>
                        </Link>
                      )}
                      {(category?.write || category?.read) && (
                        <Link to="/buyerfee">
                          <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}                        >
                            Buyer Finder fee
                          </a>
                        </Link>
                      )}
                    </div>
                  </div>
                </li>
              </>
            ) : null}
            <>
              <li
                className="nav-item"
                style={{
                  display: `${(decoded?.data?.user?.seller_id === 505 || decoded?.data?.user?.email == "tf1001@ondc.org" || decoded?.data?.user?.email == "tf1002@ondc.org") ? "none" : "block"
                    }`,
                }}
              >
                <Link to="#">
                  <a
                    className="nav-link collapsed d-flex justify-content-between align-items-center myaddedclass"
                    href="replace"
                    data-toggle="collapse"
                    data-target="#mastercatalog"
                    aria-expanded="true"
                    aria-controls="collapseTwo"
                    id="master-catalog"
                    onClick={() => handleClick("master-catalog")}
                  >
                    <div className={styles.listItems}>
                      {/* <i className="fas fa-fw fa-tablet"></i> */}
                      <ListAltIcon className={styles.icon_color}></ListAltIcon>
                      <span>Catalog</span>
                    </div>

                    <i
                      className={
                        arrows["master-catalog"]
                          ? `fas fa-angle-down `
                          : `fas fa-angle-right `
                      }
                    ></i>
                  </a>
                </Link>
                <div
                  id="mastercatalog"
                  className="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordionSidebar"
                >
                  <div
                    className={`bg-white  collapse-inner rounded ${styles.tabsInnerDiv}`}
                  >
                    {(current_sellerid < 2 && current_sellerid !== 0 && product.read) ? (
                      <>
                        {" "}
                        <Link to="/mastercatalog">
                          <a
                            href="replace"
                            className={`collapse-item  ${styles.collapseitemText}`}
                          >
                            Add Catalog Product
                          </a>
                        </Link>
                        <Link to="/masterproductlist">
                          <a
                            href="replace"
                            className={`collapse-item  ${styles.collapseitemText}`}
                          >
                            Master Product List
                          </a>
                        </Link>
                        <Link to="/logrejection">
                          <a
                            href="replace"
                            className={`collapse-item  ${styles.collapseitemText}`}
                          >
                            Catalog Rejection
                          </a>
                        </Link>
                      </>
                    ) : (
                      <>
                        {/* <Link to="/catalog">
                          <a
                            href="replace"
                            className={`collapse-item  ${styles.collapseitemText}`}
                          >
                            Master Product Lists
                          </a>
                        </Link> */}
                        {/* New Changes Sidebar  Tabs */}
                        {product.write ? <Link to="/productcatalog">
                          <a
                            href="replace"
                            className={`collapse-item  ${styles.collapseitemText}`}
                          >
                            Master Catalog
                          </a>
                        </Link> : null}
                        {product.read ? <Link to="/instorecatalog">
                          <a
                            href="replace"
                            className={`collapse-item  ${styles.collapseitemText}`}
                          >
                            In-Store Products
                          </a>
                        </Link> : null}
                        {product.write ? <Link to="/createproduct">
                          <a
                            href="replace"
                            className={`collapse-item  ${styles.collapseitemText}`}
                          >
                            Create Product
                          </a>
                        </Link> : null}
                        {/*  */}
                        {product.read ? <Link to="/ondclistedproduct">
                          <a
                            href="replace"
                            className={`collapse-item  ${styles.collapseitemText}`}
                          >
                            Products Sold
                          </a>
                        </Link> : null}
                      </>
                    )}
                  </div>
                </div>
              </li>
            </>
          </> : null}

          <>
            <li
              className="nav-item"
              style={{
                display: `${(decoded?.data?.user?.seller_id === 505 || decoded?.data?.user?.email == "tf1001@ondc.org" || decoded?.data?.user?.email == "tf1002@ondc.org") ? "block" : "none"
                  }`,
              }}
            >
              <Link to="/allorderslist">
                <a
                  className="nav-link  d-flex justify-content-between myaddedclass"
                  href="replace"
                  id="order"
                  onClick={() => handleClick("issues")}
                >
                  <div className={styles.listItems}>
                    {/* <i className="fas fa-fw fa-cog"></i> */}
                    <ReceiptLongIcon></ReceiptLongIcon>
                    <span>Order </span>
                  </div>

                </a>
              </Link>
            </li>
          </>
          <>
            <li
              className="nav-item"
              style={{
                display: `${(decoded?.data?.user?.seller_id === 505 || decoded?.data?.user?.email == "tf1001@ondc.org" || decoded?.data?.user?.email == "tf1002@ondc.org") ? "block" : "none"
                  }`,
              }}
            >
              <Link to="/rspdashboard">
                <a
                  className="nav-link  d-flex justify-content-between myaddedclass"
                  href="replace"
                  id="order"
                  onClick={() => handleClick("issues")}
                >
                  <div className={styles.listItems}>
                    <i className="fas fa-fw fa-cog"></i>
                    <span>RSF </span>
                  </div>

                </a>
              </Link>
            </li>

            <li
              className="nav-item"
              style={{
                display: `${(decoded?.data?.user?.seller_id === 505 || decoded?.data?.user?.email == "tf1001@ondc.org" || decoded?.data?.user?.email == "tf1002@ondc.org") ? "block" : "none"
                  }`,
              }}
            >
              <Link to="/supportlist" >
                <a className="nav-link  d-flex justify-content-between myaddedclass" href="replace" id="order" onClick={() => handleClick("issues")}
                >
                  <div className={styles.listItems}>
                    <ReceiptLongIcon></ReceiptLongIcon>
                    <span> Customer Support </span>
                  </div>

                </a>
              </Link>
            </li>
          </>

          {/* MasterCatalog  field end:- */}




          {(order?.read || transaction?.read) ? (
            <>
              <hr className="sidebar-divider mt-0" />
              <div className="sidebar-heading" style={{
                display: `${(decoded?.data?.user?.seller_id === 505 || decoded?.data?.user?.email == "tf1001@ondc.org" || decoded?.data?.user?.email == "tf1002@ondc.org") ? "none" : "block"
                  }`,
              }} >Order Management</div>

              {order?.read ? <li className="nav-item">
                <Link to="#">
                  <a className="nav-link collapsed d-flex justify-content-between myaddedclass" href="replace" data-toggle="collapse" data-target="#collapseTwo_1" aria-expanded="true" aria-controls="collapseTwo" id="orders"
                    onClick={() => handleClick("orders")} >
                    <div className={styles.listItems}>
                      <ReceiptLongIcon className={styles.icon_color}></ReceiptLongIcon>
                      <span>Orders</span>
                    </div>

                    <i className={arrows["orders"] ? `fas fa-angle-down` : `fas fa-angle-right`}  ></i>
                  </a>
                </Link>
                <div id="collapseTwo_1" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">

                  <div className={`bg-white  collapse-inner rounded ${styles.tabsInnerDiv}`}>
                    {order?.read ? (
                      <>
                        {((current_sellerid === 1 && decoded?.data?.user?.parent_id === 0) || (current_sellerid == 0 && decoded?.data?.user?.parent_id === 1)) &&
                          <Link to="/orderlist">
                            <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}
                            >
                              Order List
                            </a>
                          </Link>
                        }

                        {/*  */}
                        {decoded?.data?.user?.seller_id > 1 || (decoded?.data?.user?.seller_id === 0 && decoded?.data?.user?.parent_id !== 1) ?
                          <>
                            <Link to="/customerorder">
                              <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}
                              >
                                Active Orders
                              </a>
                            </Link>
                            <Link to="/completeOrder">
                              <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}
                              >
                                Complete Orders
                              </a>
                            </Link>
                            <Link to="/cancelOrderlist">
                              <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}
                              >
                                Cancelled Orders
                              </a>
                            </Link>

                          </> :
                          null}
                        <Link to="/returnorders">
                          <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}
                          >
                            Return Orders List
                          </a>
                        </Link>
                        {/*  */}
                        {(current_sellerid === 1 || decoded.data.user.id=== 493 ) ? <> <>

                          <Link to="/allorderslist">
                            <a href="replace" id="order" className={`collapse-item  ${styles.collapseitemText}`} >
                              Order
                            </a>
                          </Link>

                        </>
                          <>
                          {decoded.data.user.id!== 493 &&  <Link to="/rspdashboard">
                              <a href="replace" id="order" className={`collapse-item  ${styles.collapseitemText}`} >
                                RSF
                              </a>
                            </Link>}

                          </>  </> : null}


                      </>
                    ) : null}
                  </div>
                </div>
              </li> : null}
            </>
          ) : null}


          {(transaction?.read && decoded.data.user.parent_id !== 519) ? (
            <>
              <li className="nav-item">

                <Link to="#">
                  <a className="nav-link collapsed d-flex justify-content-between myaddedclass" href="replace" data-toggle="collapse" data-target="#collapseTwo1_1" aria-expanded="true" aria-controls="collapseTwo" id="transaction"
                    onClick={() => handleClick("transaction")}
                  >

                    <div className={styles.listItems}>
                      <PaidIcon className={styles.icon_color}></PaidIcon>
                      <span>Transaction</span>
                    </div>
                    <i className={arrows["transaction"] ? `fas fa-angle-down` : `fas fa-angle-right`}  ></i>
                  </a>
                </Link>

                <div
                  id="collapseTwo1_1"
                  className="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordionSidebar"
                >
                  <div
                    className={`bg-white collapse-inner rounded ${styles.tabsInnerDiv}`}
                  >

                    {transaction?.read ? (
                      <Link to="/unsettledtransactions">
                        <a
                          href="replace"
                          className={`collapse-item  ${styles.collapseitemText}`}
                        >
                          Unsettled  Transaction
                        </a>
                      </Link>
                    ) : null}

                    {transaction?.read ? (
                      <Link to="/transaction">
                        <a
                          href="replace"
                          className={`collapse-item  ${styles.collapseitemText}`}
                        >
                          Settled  Transaction
                        </a>
                      </Link>
                    ) : null}
                    {/* {transaction?.read ? (
                      <Link to="/payments">
                        <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}>
                          Payments
                        </a>
                      </Link>
                    ) : null} */}
                  </div>
                </div>
              </li>
            </>
          ) : null}

          {/*----------------------------------- IGM Start---------------------------------------------------------- */}


          {(igm?.read || igm?.write) ? <>
            <hr className="sidebar-divider mt-0" />
            <div className="sidebar-heading" >Grievance Management</div>
            <>
              <li className="nav-item">
                <Link to="#">
                  <a className="nav-link collapsed d-flex justify-content-between myaddedclass" href="replace" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree" id="issues"
                    onClick={() => handleClick("issues")}
                  >
                    <div className={styles.listItems}>
                      <ContactSupportIcon className={styles.icon_color}></ContactSupportIcon>
                      <span>IGM Portal </span>
                    </div>

                    <i className={arrows["issues"] ? `fas fa-angle-down` : `fas fa-angle-right`}></i>

                  </a>
                </Link>
                <div id="collapseThree" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar"                  >
                  <div className={`bg-white collapse-inner rounded ${styles.tabsInnerDiv}`}  >

                    <Link to="/issues"  >
                      <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}  >
                        IGM
                      </a>
                    </Link>

                    {/*----------------- SHOWING CUSTOMER SUPPORT TO ADMIN ONLY  -----------------------*/}
                    {current_sellerid === 1 ? <Link to="/supportlist"  >
                      <a href="replace" className={`collapse-item  ${styles.collapseitemText}`} >
                        Customer Support
                      </a>
                    </Link> : null}
                    {/*----------------- SHOWING CUSTOMER SUPPORT TO ADMIN ONLY  -----------------------*/}

                    <Link to="/grievancesettings"  >
                      <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}  >
                        Grievance Settings
                      </a>
                    </Link>
                  </div>
                </div>
              </li>
            </>
          </> : null}
          {/*--------------------------------- IGM END ------------------------------------------*/}

          {/* ----------------------------------------------------------SALES CHANNEL START------------------------------- */}

          {(igm?.read || igm?.write) ? <>
            <hr className="sidebar-divider mt-0" />
            <div className="sidebar-heading" >Sales Channel</div>
            <>
              <li className="nav-item">
                <Link to="#">
                  <a className="nav-link collapsed d-flex justify-content-between myaddedclass" href="replace" data-toggle="collapse" data-target="#collapseSalesChannel" aria-expanded="true" aria-controls="collapseSalesChannel" id="saleschannels"
                    onClick={() => handleClick("saleschannels")}
                  >
                    <div className={styles.listItems}>
                      <MovingIcon className={styles.icon_color}></MovingIcon>
                      <span>Channels</span>
                    </div>

                    <i className={arrows["saleschannels"] ? `fas fa-angle-down` : `fas fa-angle-right`}></i>

                  </a>
                </Link>
                <div id="collapseSalesChannel" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar"                  >
                  <div className={`bg-white collapse-inner rounded ${styles.tabsInnerDiv}`}  >

                    <Link to="/ondcsaleschannel"  >
                      <a href="replace" className={` d-flex justify-content-between align-items-center  collapse-item  ${styles.collapseitemText}`}  >
                      <span>ONDC</span>   <span style={{color: "green", fontSize: "12px", fontWeight: "900"}}>Active</span>
                      </a>
                    </Link>

                    {/*----------------- SHOWING CUSTOMER SUPPORT TO ADMIN ONLY  -----------------------*/}
                    {current_sellerid === 1 ? 
                      <a  className={` d-flex justify-content-between align-items-center collapse-item  ${styles.collapseitemText}`} >
                       <span>Amazon</span>   <span style={{color: "red", fontSize: "12px", fontWeight: "900"}}>Coming Soon</span>
                      </a>
                     : null}
                    {/*----------------- SHOWING CUSTOMER SUPPORT TO ADMIN ONLY  -----------------------*/}

                  
                      <a className={`d-flex justify-content-between align-items-center collapse-item  ${styles.collapseitemText}`}  >
                      <span>Flipkart</span>   <span style={{color: "red", fontSize: "12px", fontWeight: "900"}}>Coming Soon</span>  
                      </a>
                  
                   
                      <a className={`d-flex justify-content-between align-items-center collapse-item  ${styles.collapseitemText}`}  >
                      <span>Unicommerce</span>   <span style={{color: "red", fontSize: "12px", fontWeight: "900"}}>Coming Soon</span>
                      </a>
                   
                  </div>
                </div>
              </li>
            </>
          </> : null}

          {/* ----------------------------------------------------------SALES CHANNEL END------------------------------- */}


          {/* --------------------------------------SETTINGS------------------------------------ */}

          <section style={{
            display: `${(decoded?.data?.user?.seller_id === 505 || decoded?.data?.user?.email == "tf1001@ondc.org" || decoded?.data?.user?.email == "tf1002@ondc.org") ? "none" : "block"
              }`,
          }}>
            <hr className="sidebar-divider mt-0" />
            {(user?.read || user?.write || role?.write) && <div className="sidebar-heading" >Interface</div>}

            <>
              <li className="nav-item" >
                <Link to="#">
                  <a className="nav-link collapsed d-flex justify-content-between myaddedclass" href="replace" data-toggle="collapse" data-target="#collapseAnalytics" aria-expanded="true" aria-controls="collapseAnalytics" id="analytics" onClick={() => handleClick("analytics")}                  >
                    <div className={styles.listItems}>
                      <SettingsApplicationsIcon className={styles.icon_color}></SettingsApplicationsIcon>
                      <span>Analytics </span>
                    </div>
                    <i className={arrows["analytics"] ? `fas fa-angle-down` : `fas fa-angle-right`}></i>
                  </a>
                </Link>
                <div id="collapseAnalytics" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar"
                >
                  <div className={`bg-white collapse-inner rounded ${styles.tabsInnerDiv}`} >
                    <a >
                      <a href="https://app.powerbi.com/reportEmbed?reportId=32a9defa-cb8b-4174-b0db-b46dd51168ed&autoAuth=true&ctid=bf6a59b4-348c-4cfa-8b66-1ed70ae6a714" target="_blank" className={`collapse-item  ${styles.collapseitemText}`} rel="noreferrer" >
                        Analytics
                      </a>

                    </a>
                    <a >
                      <Link to="/loglist"> <a  >
                        Logs
                      </a>
                      </Link>

                    </a>

                  </div>


                </div>
              </li>

              <li className="nav-item">
                {user?.read || user?.write || role?.write ? (
                  <>
                    <Link
                      to="#"
                      className="nav-link collapsed d-flex justify-content-between align-items-center myaddedclass"
                      data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseTwo" id="users"
                      onClick={() => handleClick("users")}
                    >
                      <div className={styles.listItems}>
                        <SupervisedUserCircleIcon className={styles.icon_color}></SupervisedUserCircleIcon>
                        <span>Users</span>
                      </div>
                      <i className={arrows["users"] ? `fas fa-angle-down ` : `fas fa-angle-right `} ></i>
                    </Link>
                    <div id="collapseOne" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar"
                    >
                      <div className={`bg-white collapse-inner rounded ${styles.tabsInnerDiv}`}                      >
                        {role?.write ? (
                          <Link to="/roles">
                            <a href="replace" className={`collapse-item text-capitalize  ${styles.collapseitemText}`}
                            >
                              Roles
                            </a>
                          </Link>
                        ) : null}
                        {user?.write ? (
                          <Link to="/adduser">
                            <a
                              href="replace"
                              className={`collapse-item  text-capitalize ${styles.collapseitemText}`}
                            >
                              Add user
                            </a>
                          </Link>
                        ) : null}
                        {user?.read ? (
                          <Link to="/userlist">
                            <a
                              href="replace"
                              className={`collapse-item   text-capitalize ${styles.collapseitemText}`}
                            >
                              User List
                            </a>
                          </Link>
                        ) : null}
                      </div>
                    </div>
                  </>
                ) : null}
              </li>

              <li className="nav-item ">
                {seller?.read || seller?.write || tax?.write ? (
                  <>
                    <Link to="#" className="nav-link collapsed d-flex justify-content-between align-items-center myaddedclass" data-toggle="collapse" data-target="#seller11" aria-expanded="true"
                      aria-controls="collapseTwo"
                      onClick={() => handleClick("sellers")}
                    >
                      <div className={styles.listItems}>
                        <GroupIcon className={styles.icon_color}></GroupIcon>
                        <span>Sellers Management</span>
                      </div>

                      <i className={arrows["sellers"] ? `fas fa-angle-down ` : `fas fa-angle-right `}  ></i>
                    </Link>


                    <div id="seller11" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar"  >

                      <div className={`bg-white  collapse-inner rounded ${styles.tabsInnerDiv}`}    >

                        {seller?.write ? (
                          <Link to="/addseller">
                            <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}       >
                              Add seller
                            </a>
                          </Link>
                        ) : null}

                        {seller?.read ? (
                          <Link to="/sellerlist">
                            <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}     >
                              Seller List
                            </a>
                          </Link>
                        ) : null}

                        {tax.write ? (
                          <Link to="/tax">
                            <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}    >
                              Tax
                            </a>
                          </Link>
                        ) : null}
                      </div>
                    </div>
                  </>
                ) : null}



              </li>


              {product.write && <li className="nav-item"              >
                <Link to="#">
                  <a
                    className="nav-link collapsed d-flex justify-content-between myaddedclass"
                    href="replace"
                    data-toggle="collapse"
                    data-target="#collapseSettings"
                    aria-expanded="true"
                    aria-controls="collapseSettings"
                    id="settings"
                    onClick={() => handleClick("settings")}
                  >
                    <div className={styles.listItems}>
                      {/* <i className="fas fa-fw fa-cog"></i> */}
                      <SettingsApplicationsIcon className={styles.icon_color}></SettingsApplicationsIcon>
                      {/* <span>IGM Portal </span> */}
                      <span>Settings </span>
                    </div>
                    <i
                      className={
                        arrows["settings"]
                          ? `fas fa-angle-down`
                          : `fas fa-angle-right`
                      }
                    ></i>
                  </a>
                </Link>
                <div
                  id="collapseSettings"
                  className="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordionSidebar"
                >
                  <div
                    className={`bg-white collapse-inner rounded ${styles.tabsInnerDiv}`}
                  >
                    <Link to="/settings"

                    >
                      <a
                        href="replace"
                        className={`collapse-item  ${styles.collapseitemText}`}
                      >
                        Settings
                      </a>
                    </Link>

                  </div>
                </div>
              </li>}
            </>
          </section>

          {/* <BarChart
            width={250}
            height={200}
            series={[
              { ...seriesA, stack: 'total' },
              { ...seriesB, stack: 'total' },
              { ...seriesC, stack: 'total' },
            ]}
          /> */}
        </ul>
      ) : (
        <></>
      )}
    </>
  );
};

export default Sidebar;
